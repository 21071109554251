@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.headerBar {
  display: flex;
  align-items: center;
  gap: size-rem(40px);

  &__order {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    font-size: size-rem(16px);
    color: $clr-white;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: size-rem(12px);
  }

  &__iconwrapper {
    position: relative;
    @include flex-center;
    width: size-rem(50px);
    height: size-rem(50px);
    background: $clr-white;
    border-radius: 50%;
    &__favor {
      position: absolute;
      top: 0px;
      right: 0px;

      width: size-rem(14px);
      height: size-rem(14px);
      border-radius: 50%;
      background: $clr-green;
    }
  }

  &__button {
    width: size-rem(188px);
    height: size-rem(50px);
  }

  &__heart {
    display: flex;
    & > svg {
      width: size-rem(15.98px);
      height: size-rem(13.8px);
    }
  }
}

.headerBarWhite {
  &__button {
    border: 1px solid $clr-grey;
    border-radius: size-rem(37px);
  }

  &__iconwrapper {
    border: 1px solid $clr-grey;
    border-radius: size-rem(37px);
  }

  &__order {
    color: $clr-dark-grey;
    text-transform: uppercase;
  }

  &__text {
    color: $clr-dark-black;
  }
}
