@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.formCallTitle {
  margin-top: size-rem(140px);
  margin-bottom: size-rem(132px);

  p {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 300;
    font-size: size-rem(85px);
    line-height: 95%;
    color: $clr-white;
    margin: 0;
  }

  @include vp--740 {
    margin-top: size-rem(70px, true);
    margin-bottom: size-rem(75px, true);
    z-index: 10;
    position: relative;

    p {
      font-size: size-rem(40px, true);
    }
  }

  &__bigForm {
    margin-top: 5% !important;
    margin-bottom: size-rem(52px) !important;
  }
}

.formCallTitle :last-child {
  margin-left: size-rem(0px);
}

.formCallInputBox {
  margin-bottom: size-rem(24px);
  position: relative;
  flex: 1;
  padding: 0 size-rem(12px);
  background-color: $clr-black;
  color: $clr-white;
  box-sizing: border-box;

  @include vp--740 {
    margin-bottom: size-rem(40px, true);
  }
}

.formCallBtnBox {
  @include flex-row;
  align-items: center;
  margin-top: size-rem(36px);

  @include vp--740 {
    margin-top: size-rem(40px, true);
  }
}

.formCallBtnText,
.formCallBtnText a {
  width: size-rem(460px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 160%;
  color: $clr-white;
  margin-left: size-rem(36px);
  letter-spacing: -0.32px;

  @include vp--740 {
    width: size-rem(190px, true);
    height: fit-content;
    font-size: size-rem(12px, true);
    margin-left: size-rem(20px, true);
    line-height: 140%;
    letter-spacing: -0.11px;
  }
}

.formCallBtnText a {
  margin-left: size-rem(0px);
  text-decoration-line: none;
  text-decoration-line: underline;
  text-underline-offset: size-rem(5px);
  transition: all 0.9 ease-out;
  opacity: 1;

  &:hover {
    text-decoration-line: underline;
    text-underline-offset: size-rem(5px);
    opacity: 0.9;
    transition: all 0.9 ease-out;
  }

  @include vp--740 {
    margin-left: size-rem(0px, true);
    text-underline-offset: size-rem(5px, true);
  }
}

.formCallThanksText {
  @include vp--740 {
    margin-left: 3%;
  }
}

.formCallThanksText p {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 90%;
  color: $clr-white;
  margin-bottom: size-rem(30px);

  @include vp--740 {
    font-size: size-rem(40px, true);
  }
}

.formCallThanksText :last-child {
  width: size-rem(556px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(30px);
  line-height: 130%;
  color: $clr-white;
  letter-spacing: -0.01em;
  opacity: 0.9;
  margin-bottom: size-rem(0px);

  @include vp--740 {
    width: size-rem(340px, true);
    font-size: size-rem(20px, true);
    margin-top: size-rem(10px, true);
  }
}

.formCallForm {
  box-sizing: border-box;
}

.formCallTextarea:-webkit-autofill,
.formCallTextarea:-webkit-autofill:hover,
.formCallTextarea:-webkit-autofill:focus,
.formCallTextarea:-webkit-autofill:active {
  border-bottom: 1px solid $clr-white;
  -webkit-text-fill-color: $clr-white;
  -webkit-box-shadow: inset 0 0 0 50px $clr-black !important;
  transition: background-color 5000s ease-in-out 0s;
  font-family: $main-font-family;
}

.formCallTextarea:-webkit-autofill::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: $clr-white;
  width: size-rem(712px);
  top: 10px;

  @include vp--740 {
    width: size-rem(340px, true);
  }
}

.formCallTextarea {
  width: size-rem(712px);
  height: size-rem(70px);
  background-color: $clr-black;
  border: none;
  border-bottom: 1px solid $clr-dark-grey;
  padding: size-rem(25px) size-rem(18px) size-rem(5px);
  transition: 0.3s;
  outline: none;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(32px);
  line-height: 100%;
  display: flex;
  align-items: center;
  color: $clr-grey;
  box-sizing: content-box;

  @include vp--740 {
    width: size-rem(340px, true);
    height: size-rem(50px, true);
    padding: size-rem(17px, true) size-rem(9px, true) size-rem(3px, true);
    font-size: size-rem(22px, true);
  }

  &:-internal-autofill-selected {
    background-color: $clr-black !important;
    border-color: $clr-grey;
    font-family: $main-font-family;
    color: $clr-grey !important;
    font-size: size-rem(32px);

    @include vp--740 {
      font-size: size-rem(22px, true);
    }
  }

  &::placeholder {
    color: transparent;
    opacity: 0;
  }

  &:focus,
  &:not(:placeholder-shown) {
    + label {
      height: size-rem(38px);
      font-size: size-rem(16px);

      @include vp--740 {
        height: size-rem(32px, true);
        font-size: size-rem(12px, true);
      }
    }
  }

  &[data-has-error='true'] {
    border-color: $clr-red !important;
  }

  &:focus {
    background-color: $clr-black !important;
    border-color: $clr-white;
    font-family: $main-font-family;
    color: $clr-white !important;
    font-style: normal;
    font-weight: 300;
    font-size: size-rem(32px);

    @include vp--740 {
      font-size: size-rem(22px, true);
    }
  }
}

.formCallTextareaError {
  margin-top: 8px;
  color: $clr-red;
  font-size: 12px;
  line-height: 1em;
  font-family: $primary-font-family;

  &:empty {
    display: none;
  }
}

.formCallContainer {
  &__bigForm {
    form div input {
      height: size-rem(55px);
      @include vp--740 {
        height: size-rem(45px, true);
      }
    }
  }
}
