@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.modalMenuRoutes {
  display: flex;
  flex-direction: row;
  gap: size-rem(88px);

  @include vp--740 {
    margin-top: size-rem(30px, true);
    gap: size-rem(20px, true);
    margin-bottom: size-rem(17px, true);
    display: block;
    justify-content: initial;
    height: 80vh;
    width: 105%;
    overflow-y: scroll;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: size-rem(30px);
    width: size-rem(481px);
    @include vp--740 {
      gap: size-rem(20px, true);
      margin-bottom: size-rem(20px, true);
      width: 100%;
    }
  }

  &__route {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    font-size: size-rem(40px);
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #1c1c1c;
    transition: all 0.5s;

    &:hover {
      color: $clr-green;
    }

    @include vp--740 {
      font-size: size-rem(20px, true);
      line-height: 130%;
    }
  }

  &__active {
    color: $clr-green;
  }
}

.loader {
  z-index: 0;
  background-color: transparent;
  position: relative;
  width: 100%;
  height: calc(100vh - size-rem(200px));
  @include vp--740 {
    height: calc(100vh - size-rem(200px, true));
  }
}
