@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.headerContainer {
  width: 100%;
  height: size-rem(130px);
  z-index: 9999999;
  position: fixed;
  will-change: top;
  transition: background-color 0.3s;

  @include vp--740 {
    height: size-rem(85px, true);
    z-index: 9999;
    will-change: height;
    top: 0;
    box-sizing: border-box;
  }
}

.headerBox {
  width: 90%;
  height: 100%;
  @include flex-row_sb;
  margin: auto;
  align-items: center;
  position: relative;
}

.svgClass {
  width: size-rem(150px);
  height: size-rem(27px);

  @include vp--740 {
    width: size-rem(150px, true);
    height: size-rem(27px, true);
  }
}

.headerNavMenu {
  width: 55%;
  @include flex-row_sb;
  align-items: center;
}

.headerNavItem {
  text-decoration: none;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 140%;
  letter-spacing: -0.01em;
  color: $clr-white;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: size-rem(5px);
  }
}

.headerContainer__white {
  background-color: $clr-white;

  .headerBox {
    .headerNavMenu {
      .headerNavItem {
        color: $clr-black;

        &:hover {
          text-decoration: underline;
          text-underline-offset: size-rem(5px);
        }
      }
    }

    .headerBtn {
      border: 1px solid $clr-grey;
      border-radius: size-rem(37px);
    }
  }
}

.blackOnTransparent {
  background-color: transparent;
}

.whiteOnTransparent {
  background-color: transparent;
  color: white !important;
}

.multiOnTransparent {
  background-color: transparent;
}

.headerBurgerMenu {
  @include vp--740 {
    order: 1;
    width: size-rem(25px, true);
    height: size-rem(26px, true);
    @include flex-column_sb;
    overflow: visible;
    box-sizing: content-box;
    justify-content: space-around !important;
  }
}

.blackBurger {
  position: relative;
  overflow: visible;
  width: size-rem(25px, true);
  height: size-rem(1px, true);
  border-radius: size-rem(1px);
  background-color: $clr-black;
}

.whiteBurger {
  position: relative;
  overflow: visible;
  width: size-rem(25px, true);
  height: size-rem(1px, true);
  border-radius: size-rem(1px);
  background-color: $clr-white;
}
