@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.headerBar {
  display: flex;
  align-items: center;
  gap: size-rem(40px);

  &__order {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    font-size: size-rem(16px);
    color: $clr-white;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: size-rem(12px);

    @include vp--740 {
      gap: size-rem(15px, true);
    }
  }

  &__iconwrapper {
    position: relative;
    @include flex-center;
    width: size-rem(50px);
    height: size-rem(50px);
    background: $clr-white;
    border-radius: 50%;
    @include vp--740 {
      width: size-rem(50px, true);
      height: size-rem(50px, true);
      background: $clr-dark-black;
    }
    &__favor {
      position: absolute;
      top: 0px;
      right: 0px;

      font-size: size-rem(11px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $clr-white;
      font-family: $main-font-family;

      width: size-rem(16px);
      height: size-rem(16px);
      border-radius: 50%;
      background: $clr-green;

      @include vp--740 {
        width: size-rem(16px, true);
        height: size-rem(16px, true);
        font-size: size-rem(11px, true);
      }
    }
  }

  &__button {
    width: size-rem(188px);
    height: size-rem(50px);
    &:focus {
      transition: all 0.5s ease;
    }

    @include vp--740 {
      height: size-rem(50px, true);
    }
  }

  &__heart {
    display: flex;
    & > svg {
      width: size-rem(15.98px);
      height: size-rem(13.8px);
    }

    @include vp--740 {
      & > svg {
        width: size-rem(15.98px, true);
        height: size-rem(13.8px, true);

        & path {
          fill: $clr-white;
        }
      }
    }
  }

  &__zeroGap {
    gap: 0;
  }

  &__addGap {
    gap: size-rem(12px);
  }
}

.headerBarWhite {
  &__button {
    border: 1px solid $clr-grey;
    border-radius: size-rem(37px);

    @include vp--740 {
      width: size-rem(300px, true);
      height: size-rem(50px, true);
      font-weight: 400;
      font-size: size-rem(16px, true);
      line-height: 160%;
      border-radius: size-rem(37px, true);
    }
  }

  &__iconwrapper {
    border: 1px solid $clr-grey;
    border-radius: size-rem(37px);
    @include vp--740 {
      border-radius: 50%;
    }
  }

  &__order {
    color: $clr-dark-grey;
    text-transform: uppercase;
  }
}

.headerBarTrans {
  &__iconwrapper {
    border: 1px solid $clr-grey;
    border-radius: size-rem(37px);
    background: transparent !important;
    @include vp--740 {
      border-radius: 50%;
    }
  }
}

.searchFlatDisabled {
  display: none;
}

.headerDropDownButton {
  position: relative;
  background: $clr-white;
  color: $clr-black;
  border-radius: size-rem(37px);
  font-family: $primary-font-family;
  cursor: pointer;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 140%;
  letter-spacing: -0.01em;
  width: size-rem(210px);
  border: 1px solid var(--line, #c4c4c4);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 25%;
    width: 50%;
    height: 0;
    background: $clr-black;
    transition: all 0.5s ease;
    z-index: 0;
  }
  &.active:after {
    height: 100%;
    top: 0%;
    left: 0%;
    width: 100%;
    border-radius: size-rem(50px);
    z-index: 1;
    color: $clr-white;
    @include vp--740 {
      display: none;
    }
  }
  &.active span {
    color: $clr-white;
    z-index: 999;
    transition: all 0.5s ease;

    @include vp--740 {
      color: $clr-white;
      top: 23%;
    }
  }

  @include vp--740 {
    font-size: size-rem(16px, true);
    border-radius: size-rem(37px, true);
    border: 1px solid #c5c5c5;
    transition: all 0.5s ease;
    width: 100%;
    background-color: $clr-dark-black;
    color: $clr-white;

    &:hover:after {
      height: 0%;
    }
    &:hover {
      transition: all 0.5s ease;
    }
  }
}

.dropDownList {
  top: calc(100% + size-rem(8px));
  right: 0;
  transition: all 0.5s ease;
  display: flex;
  width: size-rem(210px);
  padding: size-rem(20px);
  flex-direction: column;
  align-items: flex-start;
  gap: size-rem(10px);
  z-index: 1;
  position: absolute;
  border-radius: size-rem(20px);
  background: var(--white, #fff);
  border: size-rem(1px) solid var(--line, #c4c4c4);

  @include vp--740 {
    position: absolute;
    width: 100%;
    padding: size-rem(20px, true);
    gap: size-rem(10px, true);
    left: 0;
    top: calc(100% + size-rem(8px, true));
    border-radius: size-rem(20px, true);
    border: size-rem(1px, true) solid var(--line, #c4c4c4);
  }

  span {
    color: var(--black, #1c1c1c);
    font-family: $main-font-family;
    font-size: size-rem(16px);
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    vertical-align: middle;
    height: size-rem(19px);

    @include vp--740 {
      font-size: size-rem(16px, true);
      left: 0;
      bottom: 0;
      height: size-rem(19px, true);
    }
  }
  span:focus,
  span:hover,
  span:active {
    color: var(--green, #6a8860);
  }
}
.svgArrows {
  width: size-rem(24px);
  height: size-rem(25px);
  margin-left: size-rem(10px);

  @include vp--740 {
    width: size-rem(24px, true);
    height: size-rem(25px, true);
    margin-left: size-rem(10px, true);
    fill: $clr-white;
  }
}

.link {
  font-family: $main-font-family;
  margin: 0;
  padding: 0;
}
.dropdownLink {
  height: size-rem(19px);
  @include vp--740 {
    height: size-rem(19px, true);
  }
}
.dropDownContainer {
  width: 100%;
  position: relative;
}

.visualDropDown {
  background: transparent;

  @include vp--740 {
    background: $clr-dark-black;
  }
}
