@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.inputName:-webkit-autofill,
.inputName:-webkit-autofill:hover,
.inputName:-webkit-autofill:focus,
.inputName:-webkit-autofill:active {
  border-bottom: 1px solid $clr-white;
  -webkit-text-fill-color: $clr-white;
  -webkit-box-shadow: inset 0 0 0 50px $clr-black !important;
  transition: background-color 5000s ease-in-out 0s;
  font-family: $main-font-family;
}

.inputName:-webkit-autofill::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: $clr-white;
  width: size-rem(712px);
  top: 10px;

  @include vp--740 {
    width: size-rem(340px, true);
  }
}

.inputName {
  width: size-rem(712px);
  height: size-rem(70px);
  background-color: $clr-black;
  border: none;
  border-bottom: 1px solid $clr-dark-grey;
  padding: size-rem(25px) size-rem(18px) size-rem(5px);
  transition: 0.3s;
  outline: none;
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(32px);
  line-height: 100%;
  display: flex;
  align-items: center;
  color: $clr-grey;
  box-sizing: content-box;

  @include vp--740 {
    width: size-rem(340px, true);
    height: size-rem(50px, true);
    padding: size-rem(17px, true) size-rem(9px, true) size-rem(3px, true);
    font-size: size-rem(22px, true);
  }

  &:-internal-autofill-selected {
    background-color: $clr-black !important;
    border-color: $clr-grey;
    font-family: $main-font-family;
    color: $clr-grey !important;
    font-size: size-rem(32px);

    @include vp--740 {
      font-size: size-rem(22px, true);
    }
  }

  &::placeholder {
    color: transparent;
    opacity: 0;
  }

  &:focus,
  &:not(:placeholder-shown) {
    + label {
      height: size-rem(38px);
      font-size: size-rem(16px);

      @include vp--740 {
        height: size-rem(32px, true);
        font-size: size-rem(12px, true);
      }
    }
  }

  &[data-has-error='true'] {
    border-color: $clr-red !important;
  }

  &:focus {
    background-color: $clr-black !important;
    border-color: $clr-white;
    font-family: $main-font-family;
    color: $clr-white !important;
    font-style: normal;
    font-weight: 300;
    font-size: size-rem(32px);

    @include vp--740 {
      font-size: size-rem(22px, true);
    }
  }
}

.inputLabel {
  display: flex;
  height: size-rem(70px);
  background-color: $clr-black;
  align-items: center;
  position: absolute;
  top: 0;
  left: size-rem(12.5px);
  right: size-rem(12.5px);
  overflow: hidden;
  padding: 0 0;
  transition: 0.3s;
  pointer-events: none;
  font-size: size-rem(32px);
  line-height: 1.2em;
  white-space: nowrap;
  color: $clr-grey;
  font-family: $main-font-family;
  z-index: 2;

  @include vp--740 {
    font-size: size-rem(22px, true);
    height: size-rem(35px, true);
  }

  &:before {
    content: '';
    position: absolute;
    left: size-rem(1px);
    right: size-rem(20px);
    top: size-rem(1px);
    bottom: size-rem(8px);
    background-color: $clr-black;
    z-index: -1;
  }
}

.inputError {
  margin-top: 8px;
  color: $clr-red;
  font-size: 12px;
  line-height: 1em;
  font-family: $primary-font-family;

  &:empty {
    display: none;
  }
}
