@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.menuMobileContainer {
  @include vp--740 {
    width: 100%;
    height: 100vh;
    z-index: 99999;
    position: fixed;
    background-color: $clr-white;
    top: 0;
    left: 0;
    padding-bottom: size-rem(20px, true);
    box-sizing: border-box;
    padding-top: size-rem(94px, true);
  }
}

.menuMobileBox {
  @include vp--740 {
    position: absolute;
    width: 90%;
    @include flex-row_sb;
    align-items: center;
    top: 0;
    left: 5%;
    z-index: 20;
    height: size-rem(85px, true);
  }
}

.menuMobileLogo {
  @include vp--740 {
    width: size-rem(150px, true);
  }
}

.svgClass {
  @include vp--740 {
    width: size-rem(150px, true);
    height: size-rem(27px, true);
  }
}

.menuMobileNavMenu {
  @include vp--740 {
    width: 100%;
    @include flex-column_sb;
    align-items: flex-start;
    height: 50vh;
    margin-top: size-rem(100px, true);
    margin-left: 5%;
    z-index: 20;
  }
}
.menuMobileNavItem {
  @include vp--740 {
    text-decoration: none;
    min-height: size-rem(32px, true);
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: size-rem(32px, true);
    line-height: 140%;
    letter-spacing: -0.01em;
    color: $clr-black;
    z-index: 20;
    &:hover {
      border-bottom: 1px solid $clr-black;
    }
  }
}

.menuMobileBtn {
  @include vp--740 {
    width: size-rem(190px, true);
    height: size-rem(50px, true);
    border: 1px solid $clr-grey;
    border-radius: 37px;
    position: absolute;
    left: 5%;
    bottom: size-rem(100px, true);
  }
}

.menuMobileBurgerMenu {
  @include vp--740 {
    cursor: pointer;
    margin-top: size-rem(10px, true);
    margin-right: size-rem(5px, true);
    width: size-rem(28px, true);
    height: size-rem(20px, true);
    @include flex-column_sb;
  }
}

.svgClassHr_1,
.svgClassHr_2,
.svgClassHr_3 {
  @include vp--740 {
    transition: 0.4s;
    position: relative;
  }
}

.menuMobileSvg {
  @include vp--740 {
    position: absolute;
    width: size-rem(587px, true);
    height: size-rem(629px, true);
    left: size-rem(-96px, true);
    top: size-rem(-91px, true);
    z-index: 0;
    .svgClass {
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
}

.menuMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  height: 90dvh;

  &__links {
    display: flex;
    justify-content: space-between;

    @include vp--740 {
      margin-bottom: size-rem(20px, true);
    }
  }

  &__link {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    font-size: size-rem(18px, true);
    line-height: 140%;
    color: #1c1c1c;
  }
}
