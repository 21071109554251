@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.formModal {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  @include vp--740 {
    z-index: 99999;
  }
}

.formModalBack {
  position: absolute;
  background: $clr-dark-black;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.formModalContainer {
  min-width: 50%;
  height: 100%;
  position: absolute;
  margin: 0;
  left: 0;
  padding-left: size-rem(80px);
  padding-right: size-rem(12px);
  background-color: $clr-black;
  z-index: 999;
  overflow-y: auto;

  scrollbar-color: $clr-dark-black;
  scrollbar-width: thick;

  // chrome
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $clr-dark-black;
    border-top: none;
    border-bottom: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $clr-grey;
    border: 3px solid $clr-dark-black;
    border-radius: 10px;
  }

  @include vp--740 {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-right: 0;
  }
}

.formModalBtnClose {
  position: absolute;
  top: size-rem(20px);
  right: size-rem(20px);
  z-index: 9999;
  background: none !important;
  @include vp--740 {
    top: size-rem(10px, true);
    right: size-rem(30px, true);
    right: size-rem(0px, true);
  }
}

.formModalTitle {
  margin-top: 10% !important;
  margin-bottom: size-rem(35px) !important;
  @include vp--740 {
    margin-top: 18% !important;
    margin-bottom: size-rem(35px, true) !important;
  }
}

.formModalTitle p {
  font-weight: 300 !important;
  font-size: size-rem(75px) !important;
  @include vp--740 {
    font-size: size-rem(42px, true) !important;
  }
}
