@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/functions.scss';

.headerLogo {
  display: flex;
  gap: size-rem(23px);
  align-items: center;
  @include vp--740 {
    order: 1;
  }
}

.svgburger {
  cursor: pointer;
  display: flex;

  & > svg {
    width: size-rem(35px);
    height: size-rem(9px);
  }

  @include vp--tablet {
    svg {
      width: size-rem(36px);
      height: size-rem(10px);
    }
  }

  @include vp--740 {
    display: none;
  }
}

.svglogo {
  height: size-rem(27px);
  width: size-rem(150px);

  @include vp--740 {
    height: size-rem(27px, true);
    width: size-rem(150px, true);
  }
}
