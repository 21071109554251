@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.buttonClose {
  background: $clr-white;
  width: size-rem(50px);
  height: size-rem(50px);
  color: $clr-black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.8 ease;
  display: flex;
  align-items: center;
  justify-content: center;
  @include vp--740 {
    width: size-rem(50px, true);
    height: size-rem(50px, true);
  }
}

.buttonCloseIcon {
  width: size-rem(30px);
  height: size-rem(30px);
  @include vp--740 {
    width: size-rem(30px, true);
    height: size-rem(30px, true);
  }
}

.buttonCloseIconWhite {
  width: size-rem(29px);
  height: size-rem(29px);
  @include vp--740 {
    width: size-rem(22px, true);
    height: size-rem(22px, true);
  }
}
