@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.wrapper {
  @include flex-column;
  width: 90%;
  margin: auto;
  position: relative;
  z-index: 10;
}
