@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
.buttonSend {
  background: $clr-white;
  width: size-rem(160px);
  height: size-rem(160px);
  color: $clr-black;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  box-sizing: border-box;
  &:hover {
    background: $clr-black;
    transition: all 0.5s ease;
    border: 1px solid $clr-white;
  }
  &:focus {
    background: $clr-black;
    transition: all 0.5s ease;
    border: 1px solid $clr-white;
  }
  @include vp--740 {
    width: size-rem(130px, true);
    height: size-rem(130px, true);

    &:hover {
      background: $clr-white;
      transition: all 0.5s ease;
      border: 1px solid $clr-white;
    }
    &:active {
      background: $clr-black;
      transition: all 0.5s ease;
      border: 1px solid $clr-white;
    }
  }
}

.buttonSendIcon {
  width: size-rem(24px);
  height: size-rem(27px);
  @include vp--740 {
    width: size-rem(22px, true);
    height: size-rem(25px, true);
  }
}
